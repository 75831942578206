import React from 'react'
import styled, { css } from 'styled-components'
import { Row, flexRender } from '@tanstack/react-table'
import { PositionSide } from '@dextoroprotocol/sdk/types'

import { useAppSelector } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'
import { FlexDivCentered } from 'components/layout/flex'
import common from 'styles/theme/colors/common'
import { genericMemo } from 'types/helpers'
import Currency from 'components/Currency'

type TableBodyRowProps<T> = {
	row: Row<T>
	localRef: any
	onClick?: () => void
	highlightRowsOnHover?: boolean
	overviewPositionTable?: boolean
}

const TableBodyRow = genericMemo(
	<T,>({
		row,
		localRef,
		onClick,
		highlightRowsOnHover,
		overviewPositionTable,
	}: TableBodyRowProps<T>) => {
		if (overviewPositionTable) {
			const currentTheme = useAppSelector(selectCurrentTheme)
			const bgColorOverview =
				currentTheme === 'light' ? common.overviewTableBG.light : common.overviewTableBG.dark
			const backgroundColor =
				// @ts-ignore
				row.original?.activePosition?.side === PositionSide.SHORT
					? bgColorOverview.short
					: bgColorOverview.long

			return (
				<BaseOverviewTableBodyRow
					className="table-body-row"
					ref={localRef}
					onClick={onClick}
					$bgColor={backgroundColor}
					$highlightRowsOnHover={highlightRowsOnHover}
				>
					{row.getVisibleCells().map((cell, index) => (
						<TableCell
							key={cell.id}
							className="table-body-cell"
							style={{ width: cell.column.getSize(), flex: cell.column.getSize() }}
						>
							{index === 0 && (cell.row.original as any)?.market?.marketKey && (
								<Currency.Icon currencyKey={(cell.row.original as any)?.market?.marketKey} />
							)}
							{flexRender(cell.column.columnDef.cell, cell.getContext())}
						</TableCell>
					))}
				</BaseOverviewTableBodyRow>
			)
		} else {
			return (
				<BaseTableBodyRow
					className="table-body-row"
					ref={localRef}
					onClick={onClick}
					$highlightRowsOnHover={highlightRowsOnHover}
				>
					{row.getVisibleCells().map((cell) => {
						let headerValue
						const header = cell?.column?.columnDef?.header
						if (typeof header === 'function') {
							headerValue = header({ column: cell.column } as any).props?.children
						} else {
							headerValue = header
						}
						return (
							<TableCell
								key={cell.id}
								className="table-body-cell"
								style={{ width: cell.column.getSize(), flex: cell.column.getSize() }}
							>
								{
									// index === 0 &&
									((cell?.row?.original as any)?.market?.marketKey ||
										(cell?.row?.original as any)?.asset) &&
										(headerValue === 'Market' ||
											headerValue === 'Market/Side' ||
											headerValue === 'Market/Type') && (
											<Currency.Icon
												currencyKey={
													(cell?.row?.original as any)?.market?.marketKey ||
													(cell?.row?.original as any)?.asset ||
													(cell?.row?.original as any)?.marketKey
												}
												className="currency-icon-container"
											/>
										)
								}
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</TableCell>
						)
					})}
				</BaseTableBodyRow>
			)
		}
	}
)

export const BaseTableBodyRow = styled.div<{ $highlightRowsOnHover?: boolean }>`
	display: flex;
	overflow-x: auto;
	${(props) => css`
		cursor: ${props.onClick ? 'pointer' : 'default'};

		:not(:last-child) {
			border-bottom: ${props.theme.colors.selectedTheme.border};
		}

		padding: 6px 0;

		&:nth-child(even) {
			background-color: ${props.theme.colors.selectedTheme.table.default};
		}
		&:nth-child(odd) {
			background-color: ${props.theme.colors.selectedTheme.table.default};
		}

		${props.$highlightRowsOnHover &&
		css`
			&:hover {
				background-color: ${props.theme.colors.selectedTheme.table.hover};
			}
		`}
	`}
`

const BaseOverviewTableBodyRow = styled(BaseTableBodyRow)<{ $bgColor?: string }>`
	background: ${(props) => props.$bgColor};
`

export const TableCell = styled(FlexDivCentered)`
	box-sizing: border-box;
	&:first-child {
		padding-left: 18px;
	}
	&:last-child {
		padding-right: 14px;
	}
`

export default TableBodyRow
